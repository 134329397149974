<template>
  <div id="inAppEditorPage" nativeOnScroll="handleScroll">
    <el-row id="inAppEditor">
      <!-- Left Side Form -->
      <el-col :offset="1" :span="17" class="leftSideForm" v-if="content != null" v-bind:class="{ readOnly: readOnly === true }">
        <!-- Validation Error List -->
        <div class="validationErrorList" v-if="Object.keys(validationErrors).length > 0">
          <div class="errorHeader">Following issues needs to be resolved:</div>
          <div class="validationErrorItem" v-for="(message, path) in validationErrors" :key="path">- {{ message }}</div>
        </div>

        <!-- Form  -->
        <el-form ref="inAppNotificationForm" v-bind:class="{ readOnly: readOnly === true }" label-position="top" :model="content">
          <!-- Text Container -->
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>Content</span>
            </div>

            <!-- Card Content -->
            <div>
              <!-- Title -->
              <el-form-item label="Title" prop="title">
                <el-input class="with-colors" :disabled="readOnly" placeholder="Please enter title" :class="{ readOnly: readOnly }" maxlength="25" show-word-limit v-model="content.text.title"></el-input>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: content.text.titleColor }">
                    <color-picker :color="content.text.titleColor" theme="light" @changeColor="onTitleColorPicked" />
                  </div>
                  <div slot="reference" class="textColorButton" v-if="!readOnly">
                    <el-tooltip effect="dark" content="Title Color" placement="top-start">
                      <i class="fa fa-font" aria-hidden="true"></i>
                    </el-tooltip>
                    <div class="selectedColor" :style="{ background: content.text.titleColor + ' !important' }"></div>
                  </div>
                </el-popover>
                <div class="errorMsg" v-if="validationErrors['text.title']">Invalid Title.</div>
              </el-form-item>

              <!-- Description -->
              <el-form-item label="Description" prop="title">
                <el-input type="textarea" :disabled="readOnly" :class="{ readOnly: readOnly }" class="with-colors" placeholder="Please enter description" maxlength="100" show-word-limit v-model="content.text.description" :rows="3" :autosize="false" resize="none"></el-input>
                <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: content.text.descriptionColor }">
                    <color-picker :color="content.text.descriptionColor" theme="light" @changeColor="onDescriptionColorPicked" />
                  </div>
                  <div slot="reference" class="textColorButton" v-if="!readOnly">
                    <el-tooltip effect="dark" content="Description Color" placement="top-start">
                      <i class="fa fa-font" aria-hidden="true"></i>
                    </el-tooltip>
                    <div class="selectedColor" :style="{ background: content.text.descriptionColor + ' !important' }"></div>
                  </div>
                </el-popover>
                <div class="errorMsg" v-if="validationErrors['text.description']">{{ validationErrors['text.description'] }}</div>
              </el-form-item>

              <!-- Content Media Image -->
              <el-form-item label="Image" prop="title">
                <el-input class="with-colors" :disabled="readOnly" :class="{ readOnly: readOnly }" placeholder="Image URL" v-model="content.text.media.url"></el-input>
                <div class="errorMsg" v-if="validationErrors['text.media.url']">{{ validationErrors['text.media.url'] }}</div>
              </el-form-item>

              <!-- Content Vertical Position -->
              <el-form-item v-if="selectedType == 'cover' || selectedType == 'bigModal'" class="readiFormItem" label="Vertical Content Position">
                <el-radio-group v-model="content.text.positionType" :disabled="readOnly" :class="{ readOnly: readOnly }">
                  <el-radio label="top">Top</el-radio>
                  <el-radio label="center">Center</el-radio>
                  <el-radio label="bottom">Bottom</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-card>

          <!-- Background Container -->
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>Background</span>
            </div>

            <!-- Card Content -->
            <div>
              <!-- Background Color -->
              <el-form-item label="Background - Will be ignored if background image provided">
                <el-popover :disabled="readOnly" popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: content.background.bgColor }">
                    <color-picker :color="content.background.bgColor" theme="light" @changeColor="onBackgroundColorPicked" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" :style="{ 'background-color': content.background.bgColor + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ content.background.bgColor }}</span>
                  </div>
                </el-popover>
                <div class="errorMsg" v-if="validationErrors['background.bgColor']">{{ validationErrors['background.bgColor'] }}</div>
              </el-form-item>

              <!-- Background Image -->
              <br />
              <div class="clearfix">
                <span>Use Background Image</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <el-switch :disabled="readOnly" v-model="content.background.isImage" active-color="#13ce66"> </el-switch>
              </div>
              <br />
              <el-form-item label="Background Image Url" v-if="content.background.isImage">
                <el-input placeholder="Background Image Url" v-model="content.background.imageUrl"></el-input>
                <div class="errorMsg" v-if="validationErrors['background.imageUrl']">{{ validationErrors['background.imageUrl'] }}</div>
              </el-form-item>

              <br />
              <div class="clearfix" v-if="selectedType == 'cover' || selectedType == 'modal'">
                <span>Show Close Button</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <el-switch :disabled="readOnly" :class="{ readOnly: readOnly }" v-model="content.closeButton.visible" active-color="#13ce66"> </el-switch>
              </div>
              <br />
              <el-form-item v-if="content.closeButton && content.closeButton.visible" label="Close Button Color">
                <el-popover :disabled="readOnly" popper-class="customTooltip" placement="bottom" width="400" trigger="click">
                  <div :style="{ background: content.closeButton.color }">
                    <color-picker :color="content.closeButton.color" theme="light" @changeColor="onCloseIconColorPicked" />
                  </div>
                  <div class="colorButton" slot="reference">
                    <span class="colorPreview" :style="{ 'background-color': content.closeButton.color + ' !important', border: 'none', color: 'white !important' }"></span>
                    <span class="colorValue">{{ content.closeButton.color }}</span>
                  </div>
                </el-popover>
              </el-form-item>
            </div>
          </el-card>

          <!-- Buttons Container -->
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>Buttons</span>
            </div>
            <!-- Card Content - Notification Button Link -->
            <div class="buttonList">
              <div class="notificationButtonRow" :ref="'buttonListForm_' + index" label-position="top" :model="button" v-for="(button, index) in content.buttons.buttons" :key="index">
                <!-- Left Side Delete Button -->
                <div class="deleteColumn" :span="3">
                  <div>Button #{{ index + 1 }}</div>
                  <!-- <el-button type="danger">Danger</el-button> -->
                  <el-button v-if="!readOnly" :disabled="readOnly" class="deleteBtn" type="danger" circle @click="content.buttons.buttons.splice(index, 1)" icon="el-icon-delete"></el-button>
                </div>
                <!-- Right Side Button Metadata -->
                <div class="buttonContentColumn">
                  <!-- Button Text -->
                  <el-form-item label="Button Text" prop="label">
                    <!-- Button Text Color -->
                    <el-input :disabled="readOnly" :class="{ readOnly: readOnly }" class="buttonTextInput" v-model="button.text" placeholder="Enter label"></el-input>
                    <!-- Button Text color -->
                    <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click" v-if="!readOnly">
                      <div :style="{ background: button.textColor }">
                        <color-picker :color="button.textColor" theme="light" @changeColor="setButtonColor(button, true, ...arguments)" />
                      </div>
                      <div slot="reference" class="textColorButton">
                        <el-tooltip effect="dark" content="Button Text Color" placement="top-start">
                          <i class="fa fa-font" aria-hidden="true"></i>
                        </el-tooltip>
                        <div class="selectedColor" :style="{ background: button.textColor + ' !important' }"></div>
                      </div>
                    </el-popover>
                    <!-- Button Background Color -->
                    <el-popover popper-class="customTooltip" placement="bottom" width="400" trigger="click" v-if="!readOnly">
                      <div :style="{ background: button.backgroundColor }">
                        <color-picker :color="button.backgroundColor" theme="light" @changeColor="setButtonColor(button, false, ...arguments)" />
                      </div>
                      <div slot="reference" class="textColorButton">
                        <el-tooltip effect="dark" content="Button Background Color" placement="top-start">
                          <i class="fa fa-paint-brush" aria-hidden="true"></i>
                        </el-tooltip>
                        <div class="selectedColor" :style="{ background: button.backgroundColor + ' !important' }"></div>
                      </div>
                    </el-popover>
                    <!-- Button Border Radius -->
                    <el-popover placement="bottom" width="400" style="padd" trigger="click" v-if="!readOnly">
                      <el-slider v-model="button.borderRadius" label="Border Radius" :min="0" :max="25"></el-slider>
                      <div slot="reference" class="textColorButton">
                        <el-tooltip effect="dark" content="Border Radius" placement="top-start">
                          <i class="fa fa-stop" aria-hidden="true"></i>
                        </el-tooltip>
                      </div>
                    </el-popover>
                    <div class="errorMsg" v-if="validationErrors['buttons.buttons.' + index + '.text']">{{ validationErrors['buttons.buttons.' + index + '.text'] }}</div>
                  </el-form-item>
                  <!-- Button On Click Action -->
                  <el-form-item class="onClickActionContainer" label="On Click Action">
                    <el-radio-group v-model="button.closeOnClick" :disabled="readOnly" :class="{ readOnly: readOnly }">
                      <el-radio :label="true">Close Notification</el-radio>
                      <el-radio :label="false">Open Url</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <!-- Button Deep Links -->
                  <el-row v-if="!button.closeOnClick">
                    <el-col :span="12">
                      <!-- Android Deep Link -->
                      <el-form-item label="Android URL" prop="label">
                        <el-input :disabled="readOnly" v-model="button.androidUrl" placeholder="Enter label"></el-input>
                        <div class="errorMsg" v-if="validationErrors['buttons.buttons.' + index + '.androidUrl']">{{ validationErrors['buttons.buttons.' + index + '.androidUrl'] }}</div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <!-- iOS Deep Link -->
                      <el-form-item label="iOS URL" prop="label">
                        <el-input :disabled="readOnly" v-model="button.iosUrl" placeholder="Enter label"></el-input>
                        <div class="errorMsg" v-if="validationErrors['buttons.buttons.' + index + '.iosUrl']">{{ validationErrors['buttons.buttons.' + index + '.iosUrl'] }}</div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>

              <!-- Add button -->
              <div class="addBtnContainer">
                <el-button type="primary" size="mini" class="addBtn" v-if="content.buttons.buttons.length < 2 && !readOnly" plain @click="onAddButton()">Add Notification Button</el-button>
              </div>
            </div>
          </el-card>
        </el-form>
      </el-col>

      <!-- Right Side Preview -->
      <el-col :span="6">
        <div ref="mobilePreview" class="rightSidePreview">
          <div class="w-full text-center">
            <el-button class="btnSendSms center" size="mini" type="primary" @click="showSendTestNotifPopup()"> Send Test Notification </el-button>
          </div>
          <!-- Mobile Preview -->
          <div class="mobileDiv" v-if="content != null">
            <div class="inAppPopup">
              <!-- Cover -->
              <div v-if="selectedType == 'cover'" class="coverMessage" :class="{ isNotImageBackground: !content.background.isImage }" :style="{ 'background-color': content.background.bgColor, 'background-image': 'url(' + content.background.imageUrl + ')' }">
                <!-- Close Button At Top Right -->
                <div class="closeBtn" v-if="content.closeButton.visible" :style="{ color: content.closeButton.color }">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>

                <!-- Text Content -->
                <div class="textContainer" v-if="content.text.visible" :style="{ 'justify-content': getFlexValueForPosition(content.text.positionType) }">
                  <div class="title" v-if="content.text.title.length > 0" :style="{ color: content.text.titleColor }">
                    {{ content.text.title }}
                  </div>
                  <div class="description" v-if="content.text.description.length > 0" :style="{ color: content.text.descriptionColor }">
                    {{ content.text.description }}
                  </div>
                  <!-- Media if provided -->
                  <div class="mediaContainer" v-if="content.text.media.visible && content.text.media.url.length > 0" :style="{ 'background-image': 'url(' + content.text.media.url + ')' }"></div>
                </div>

                <!-- Buttons  -->
                <div class="buttonsContainer">
                  <div v-for="(button, index) in content.buttons.buttons" :key="index" class="buttonItem" :style="{ background: button.backgroundColor, color: button.textColor, 'border-radius': button.borderRadius + 'px' }">
                    {{ button.text }}
                  </div>
                </div>
              </div>

              <!-- Modal -->
              <div v-if="selectedType == 'modal' || selectedType == 'bigModal'" class="modalMessage">
                <div class="background" :style="{ 'background-color': content.containerBackground.color, opacity: content.containerBackground.opacity / 100 }"></div>

                <div class="modalContainer" :class="{ isNotImageBackground: !content.background.isImage, bigModal: selectedType == 'bigModal' }" :style="{ 'background-color': content.background.bgColor, 'background-image': 'url(' + content.background.imageUrl + ')' }">
                  <!-- Close Button At Top Right -->
                  <div class="closeBtn" v-if="content.closeButton.visible" :style="{ color: content.closeButton.color }">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </div>

                  <!-- Text Content -->
                  <div class="textContainer" v-if="content.text.visible" :style="{ 'justify-content': getFlexValueForPosition(content.text.positionType) }">
                    <div class="title" v-if="content.text.title.length > 0" :style="{ color: content.text.titleColor }">
                      {{ content.text.title }}
                    </div>
                    <div class="description" v-if="content.text.description.length > 0" :style="{ color: content.text.descriptionColor }">
                      {{ content.text.description }}
                    </div>

                    <!-- Media if provided -->
                    <div class="mediaContainer" v-if="content.text.media.visible && content.text.media.url.length > 0" :style="{ 'background-image': 'url(' + content.text.media.url + ')' }"></div>
                  </div>

                  <!-- Buttons  -->
                  <div class="buttonsContainer">
                    <div v-for="(button, index) in content.buttons.buttons" :key="index" class="buttonItem" :style="{ background: button.backgroundColor, color: button.textColor, 'border-radius': button.borderRadius + 'px' }">
                      {{ button.text }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Header & Footer -->
              <div v-if="selectedType == 'header' || selectedType == 'footer'" class="bannerMessage headerMessage" :class="{ isNotImageBackground: !content.background.isImage }" :style="{ 'background-color': content.background.bgColor, 'background-image': 'url(' + content.background.imageUrl + ')', 'align-self': getHeaderFooterFlexDirection(selectedType) }">
                <!-- Text Content -->
                <div class="textContainer" v-if="content.text.visible" :style="{ 'justify-content': getFlexValueForPosition(content.text.positionType) }">
                  <!-- Media Image Container -->
                  <div class="leftSideMedia" v-if="content.text.media.visible && content.text.media.url.length > 0" :style="{ 'background-image': 'url(' + content.text.media.url + ')' }"></div>

                  <!-- Text container -->
                  <div class="rightSideText">
                    <!-- title -->
                    <div class="title" v-if="content.text.title.length > 0" :style="{ color: content.text.titleColor }">
                      {{ content.text.title }}
                    </div>
                    <!-- Description -->
                    <div class="description" v-if="content.text.description.length > 0" :style="{ color: content.text.descriptionColor }">
                      {{ content.text.description }}
                    </div>
                  </div>
                </div>

                <!-- Buttons  -->
                <div class="buttonsContainer">
                  <div v-for="(button, index) in content.buttons.buttons" :key="index" class="buttonItem" :style="{ background: button.backgroundColor, color: button.textColor, 'border-radius': button.borderRadius + 'px' }">
                    {{ button.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- DIALOG - TYPE SELECT  -->
    <el-dialog append-to-body title="Select Layout For In-App Message" :visible.sync="messageTypeSelectorDialogVisible" custom-class="dialog-inapp-message-type" width="85%" :close-on-click-modal="content != null" center :close-on-press-escape="content != null" :show-close="content != null">
      <div class="typesContainer">
        <div class="inAppItem" v-for="(inAppType, index) in availableInAppInfo" :key="index" @click="onInAppLayoutChange(inAppType.type)">
          <div class="typeName">{{ inAppType.title }}</div>
          <img class="typeImg" :src="inAppType.image" />
          <!-- <el-button @click="onInAppLayoutChange()">{{item}}</el-button> -->
        </div>
      </div>
    </el-dialog>

    <!-- DIALOG - SEND TEST In-APP Notification -->
    <el-dialog append-to-body custom-class="inAppTestDailog" v-if="content != null" :visible.sync="showInAppNotifTestPopup" width="1000px" center title="Send Test Message ">
      <el-form ref="inAppNotificationTestForm" @submit.native.prevent>
        <!-- // Query Search  -->
        <el-form-item required>
          <!-- Header -->
          <div class="subHeader">Only users having push notification token will be available in search.</div>

          <!-- // User To Search -->
          <div class="inputDetailOfUser">
            <el-autocomplete v-model="inputOfUser" class="selectAttribute" :fetch-suggestions="fetchUserListForPushContentTest" placeholder="Search User" @select="updateSelectedUserList">
              <el-select v-model="userAttributeToSearchBy" slot="prepend" placeholder="Select user property to search by">
                <el-option label="Email" value="email"></el-option>
                <el-option label="Mobile" value="mobile"></el-option>
                <el-option label="Name" value="name"></el-option>
                <el-option label="Growlytics ID" value="id"></el-option>
              </el-select>
            </el-autocomplete>
          </div>
        </el-form-item>

        <!-- // Show User List  -->
        <div class="userListDiv" v-if="selectedUserList.length > 0">
          <el-table :data="selectedUserList">
            <el-table-column prop="email" label="Email">
              <template slot-scope="scope">
                <span v-if="!scope.row.email">--</span>
                <span v-else>{{ scope.row.email }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Name">
              <template slot-scope="scope">
                <span v-if="!scope.row.name">--</span>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="Id"> </el-table-column>
            <el-table-column prop="mobile" label="Mobile">
              <template slot-scope="scope">
                <span v-if="!scope.row.mobile">--</span>
                <span v-else>{{ scope.row.mobile }}</span>
              </template>
            </el-table-column>
            <el-table-column width="50" label="">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.$index, selectedUserList)" type="text" size="small">
                  <el-button type="text" class="deleteBtn" icon="el-icon-delete"></el-button>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- // Test Button  -->
        <div class="sendTestBtn" v-if="selectedUserList.length > 0">
          <el-button class="sendTestNotificationBtn" :loading="sendingTestNotification" type="success" size="mini" @click="onSendTestNotification()">Send a test notification</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./inAppEditor.scss"></style>

<script>
import InAppEditorComponent from './inAppEditorComponent';
export default InAppEditorComponent;
</script>
